.news-list {
  flex-grow: 1;
  padding-top: 0;
  min-width: 70%;

  .ant-card-bordered {
    border: none;
  }

  &__post {
    width: auto;
    margin-bottom: 30px;
    border-radius: 15px;
    margin-left: 0;
    margin-right: 0;
    background-color: $darkBox;
    color: $alwaysBlack;


    .ant-card-meta-title, .ant-card-meta-description {
      color: $alwaysBlack;
    }
    .ant-card-cover {
      justify-content: center;
      display: flex;
    }
    .ant-card-body {
      padding: 0;
    }

    &-img {
      width: 95%;
      margin-top: 25px;
      border-radius: 8px;
    }

    &-content {
      width: 95%;
    }

    &-content-text {
      margin-top: 20px;
      font-weight: 400;
      font-size: 17px;
    }

    .ant-card-body {
      display: flex;
      justify-content: center;
    }
  }
}

@include large-mobile {
  .news-list {
    &__post {
      .ant-card-body {
        padding: 24px 0;
      }
    }
  }
}
