@mixin font-face($font-name, $file-name) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + '.eot');
    src: url($file-name + '.eot?#iefix') format('embedded-opentype'),
    url($file-name + '.woff') format('woff'),
    url($file-name + '.woff2') format('woff2'),
    url($file-name + '.ttf') format('truetype'),
    url($file-name + '.svg##{$font-name}') format('svg');
  }
}

@include font-face('Inter-ExtraBold', '../../fonts/Inter-ExtraBold');
@include font-face('Inter-Regular', '../../fonts/Inter-Regular');
@include font-face('Inter-SemiBold', '../../fonts/Inter-SemiBold');
