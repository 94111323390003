.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background: #1095B5;
  color: #fff;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 82px;

    @include maxWidth(1074px) {
      height: auto;
      padding: 30px 0;
    }

  }

  &__info {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;

  }

  &__menu {
    position: relative;
    height: 31px;
    margin: 0;
    padding: 0 0 0 37px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    list-style: none;
    &-item {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      text-transform: uppercase;
      color: $alwaysWhite;
      margin: 0 20px 0 0;

      a {
        color: $alwaysWhite;

        &:hover {
          color: #FFB42D;
        }
      }

    }
  }

  @include maxWidth(1074px) {
    &__wrapper {
      flex-direction: column;
    }
    &__menu {
      height: fit-content;
      margin: 0 0 20px;
      padding: 0;
      justify-content: center;
      flex-wrap: wrap;
      &:before {
        display: none;
      }
      &-item {
        margin: 0 15px 10px 0;
      }
    }
    &__info {
      order: 2;
      margin-top: 10px;
    }
  }

  @include maxWidth(500px) {

    &__menu {
      flex-direction: column;
      flex-wrap: nowrap;
      &-item {
        margin: 0 0 10px 0;
      }
    }
  }
}