.main-banner {
  z-index: 1;

  .slick-slider {
    .slick-dots {
      display: inline-block !important;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 45px;
      z-index: 1;
      width: auto;

      @include media("<1200px") {
        bottom: 0;
        left: 0;
        width: 100%;
        position: relative;
        transform: none;

      }
      li {
        width: fit-content;
        height: fit-content;
        margin: 0;
        &:not(:last-child) {
          margin: 0 5px 0 0;
        }
        &.slick-active {
          button {
            width: 45px;
            background: #FF9E2C;
            border-radius: 21px;
          }
        }

        button {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: $alwaysWhite;
          padding: 4px;

          @include media("<1200px") {
            background: $alwaysGrey;
          }
          &:before {
            display: none;
          }
        }
      }
    }
  }

}

.banner-slide {
  position: relative;
  @include media("<568px") {
    padding-left: 2vw;
    padding-top: 10vw;
  }
  &__image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba($alwaysBlack, .3);
      z-index: 3;
      opacity: 0;

      @include media("<1200px") {
        opacity: 1;
      }
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include media('<1200px') {
        width: 200vw;
        margin-left: -100vw;
      }

      @include media("<568px") {
        width: 400vw;
        margin-left: -260vw;
      }
    }
  }

  &__content {
    min-height: 450px;
    position: relative;
    z-index: 5;
    padding-top: 50px;
    padding-bottom: 30px;

    @include media("<568px") {
      min-height: 360px;
      padding-top: 20px;
    }
  }
  &__title {
    font-weight: 900;
    font-size: 50px;
    line-height: 1.3;
    margin-bottom: 10px;
    font-family: $titleFont;
    color: #fff;
    max-width: 700px;

    @include media("<568px") {
      font-size: 26px;
    }
  }
  &__sub-title {
    font-size: 18px;
    line-height: 1.4;
    font-weight: 900;
    color: rgba(250,250,250, .9);
    max-width: 470px;
    @include media("<568px") {
      font-size: 14px;
    }
  }
  &__btn {
    margin-top: 45px;
  }
}