

@include large-mobile {
  .faq__bg-layer {
    .header {
      height: unset;
      padding-top: 20px;
      margin: 0 40px 67px;
      width: calc(100% - 80px);
    }

    .faq {
      &__tile-cont {
        justify-content: center;

        .tile {
          display: flex;
          justify-content: flex-start;
          height: 160px;
          padding: 20px 10px 15px;
          margin-right: 15px;
          width: 25%;

          &__img-cont {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 70px;
          }

          h4 {
            font-family: foco, sans-serif;
            font-size: 20px;
            line-height: 28px;
          }
        }
      }

      padding: 0 40px;
    }

    .footer {
    }
  }
}

@include tablet {
  .faq__bg-layer {
    .footer {
      padding-bottom: 10px;
    }

    .faq {
      padding: 0 40px;
    }
  }
}

@include desktop {
  .faq__bg-layer {
    margin: 0 auto;

    .header {
      padding-top: 55px;
      margin: 0 150px 82px;
      width: calc(100% - 300px);
    }

    .faq {
      margin: 0 150px 120px;
      padding: 0 40px;

      &__tile-cont {
        justify-content: space-between;

        .tile {
          justify-content: space-between;
          padding: 20px 30px 25px;
          margin: 0;
          width: 360px;
          height: 200px;

          &__img-cont {
            min-height: 105px;
          }

          h4 {
            font-size: 25px;
            line-height: 28px;
          }
        }
      }

      h3 {
        margin-bottom: 40px;
      }
    }

    .footer {

    }
  }
}